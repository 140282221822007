<template>
    <div class="RepoSelfRefundCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            ref="createForm"
            :model="form"
            :rules="rules"
            label-width="110px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col>
                        <el-form-item prop="type" label="退货类型">
                            <el-radio v-model="form.type" :label="0">采购订单</el-radio>
                            <el-radio v-model="form.type" :label="1">单方销售</el-radio>
                        </el-form-item>

                        <el-form-item prop="chooseType" label="退货方式">
                            <el-radio v-model="form.chooseType" :label="2">引用单据</el-radio>
                            <el-radio v-model="form.chooseType" :label="1" :disabled="ifPurchaseOrderFlag"
                                >引用商品
                            </el-radio>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <template :span="8" v-if="chooseOrderRefundFlag">
                        <el-col :span="8">
                            <el-form-item label="申请单号" prop="saleSelfOrderCode" key="orderCode">
                                <el-input
                                    v-model="form.saleSelfOrderCode"
                                    placeholder="请输入申请单号"
                                    @change="validateAndSelectSaleSelfOutOrder"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item key="deptCode" v-if="ifPurchaseOrderFlag" label="进货机构" prop="deptCode">
                                <el-select placeholder="请选择" v-model="form.deptCode">
                                    <el-option label="请选择" value="" />
                                    <el-option
                                        v-for="storeDept in storeDepts"
                                        :label="storeDept.name"
                                        :value="storeDept.code"
                                        :key="storeDept.code"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item key="deptName" v-else label="进货机构" prop="deptName">
                                <el-input readonly v-model.trim="form.deptName" />
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :span="8" v-else>
                        <el-form-item label="商品查询条件" key="search">
                            <el-input v-model="search" placeholder="商品名称/条码/助记码/自编码" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退货配送中心" prop="repoCode">
                            <el-select v-model="form.repoCode" @change="changeRepo" filterable ref="repoSelect">
                                <el-option
                                    v-for="item in depts"
                                    :label="item.name"
                                    :value="item.code"
                                    :disabled="item.disabled"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly :value="this.$store.state.session.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="formExtend.createTime" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button
                    v-if="chooseOrderRefundFlag"
                    type="primary"
                    @click="validateAndSelectSaleSelfOutOrder"
                    size="small"
                >
                    查询单号
                </el-button>
                <template v-else>
                    <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton">
                        查询并添加
                    </el-button>
                    <el-button
                        type="primary"
                        @click="showPickUpGoods"
                        size="small"
                        :disabled="disableShowPickUpGoodsDialog"
                    >
                        选择商品基本信息
                    </el-button>
                </template>
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <template v-if="chooseOrderRefundFlag">
                        <el-table-column label="可退数量" width="80" prop="saleCounts" key="order1" />
                        <el-table-column label="可退赠品数量" width="100" prop="saleGiftCounts" key="order2" />
                    </template>
                    <template v-else>
                        <el-table-column label="库存数量" width="80" prop="stock" key="goods" />
                    </template>
                    <el-table-column label="退货价" width="160">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="enabledBigUnit">
                        <el-table-column label="包装因子">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="退货箱数" min-width="140">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="140">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="退货数量" width="140">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :disabled="enabledBigUnit"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货金额" width="90">
                        <template slot-scope="scope"
                            ><span>
                                <span>{{ scope.row.saleRefundShowMoney }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货赠品数量" width="140">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.giftCounts'"
                                :rules="rules.giftCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货总数量" width="180">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input v-model="scope.row.totalCounts" :disabled="true" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>

<script>
import Util from 'js/Util';
import EfPriceInput from 'components/EfPriceInput';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import MoneyUtils from 'js/MoneyUtils';
import PickUpGoods from 'components/PickUpGoods';
import StockBizCommon, { packFactor, PropertyConverter } from 'js/StockBizCommon';
import { DeptTypeEnum } from 'js/DeptCommon';
import { get, postByFormDataAfterConfirmed } from 'request/http';

export default {
    name: 'RepoSelfRefundCreate',
    components: { PickUpGoods, EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            search: '',
            enabledBigUnit: false,
            formExtend: {
                deptType: null,
                createTime: Util.nowFormatDate(),
            },
            chooseOrderRefundFlag: true,
            ifPurchaseOrderFlag: true,
            form: {
                //自建退货类型
                type: 0,
                repoCode: '',
                deptCode: '',
                chooseType: 2,
                saleSelfOrderCode: '',
                bizCode: '',
                deptName: '',
                detailParams: [],
            },
            depts: [],
            storeDepts: [],
            noBizFlag: false,
            selectedDept: null,
            //所选商品详情列表
            goodsDetailMap: new Map(),
            detailParamPropertyArrForSubmit: [
                'codes',
                'goodsCode',
                'counts',
                'packFactors',
                'giftCounts',
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],
            orderQuerying: false,
            rules: {
                deptCode: {
                    required: true,
                    message: '请选择进货机构',
                    trigger: ['blur'],
                },
                type: {
                    required: true,
                    message: '退货类型必选',
                    trigger: ['blur'],
                },
                chooseType: {
                    required: true,
                    message: '请选择退货方式',
                    trigger: ['blur'],
                },
                saleSelfOrderCode: {
                    required: true,
                    message: '请填写批发订单号',
                    trigger: ['blur'],
                },
                repoCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: pricesValidateRule(2),
                counts: [
                    countValidateRule(
                        () => {
                            return this.form.detailParams || [];
                        },
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                giftCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [
                    countGreaterThanZeroValidateRule(),
                    {
                        validator: (rule, count = 0, callback) => {
                            rule.fullField.match(/\.(\d*)\./);
                            const idx = Number(RegExp.$1);
                            let canRefundCount;
                            const detailParam = this.form.detailParams[idx];
                            if (this.chooseOrderRefundFlag) {
                                canRefundCount = this.decimal(detailParam.saleCounts || 0)
                                    .add(detailParam.saleGiftCounts || 0)
                                    .toNumber();
                            } else {
                                canRefundCount = detailParam.stock || 0;
                            }
                            count <= canRefundCount ? callback() : callback(new Error('不可超过最大可退数量'));
                        },
                        trigger: ['change'],
                    },
                ],
            },
            url: {
                save: '/trade/wholesale/refund/createExtend',
                //查询交易订单
                queryOrderPurchaseSaleRelation: '/biz/purchaseSaleRelation/list?whoseSaleOrderCode=',
                //查询交易订单 前台取出订单类型
                queryOrder: '/trade/wholesale/order/info_c/',
                //查询交易对应的出库单
                queryOut: '/trade/wholesale/out/info_c/',
                //单方销售退货允许不关联单据
                noBizFlagUrl:
                    '/system/deptPrivilege/hasDeptPrivilege?flag=dept.biz.refund.self.not.choose.biz&deptCodes=',
            },
        };
    },
    mounted() {
        this.changeChooseType(this.form.chooseType);
        this.getStoreList();
    },
    watch: {
        'form.type': function (n, o) {
            if (n === 0) {
                this.ifPurchaseOrderFlag = true;
                this.form.saleSelfOrderCode = '';
                this.form.chooseType = 2;
                this.getStoreList();
            }
            if (n === 1) {
                this.form.saleSelfOrderCode = '';
                this.form.repoCode = null;
                this.ifPurchaseOrderFlag = false;
            }
        },
        'form.chooseType': function (n, o) {
            if (n === 1 || n === 2) {
                this.changeChooseType(n);
            }
        },
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return this.disableShowPickUpGoodsDialog || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.repoCode) || !this.noBizFlag;
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.repoCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        getStoreList() {
            this.$efApi.deptApi.companyAllStores().then((rst) => {
                this.$nextTick(function () {
                    this.storeDepts = rst;
                });
            });
        },
        changeChooseType(type) {
            const rules = this.rules;
            //清空重置数据
            this.rules = rules;
            this.form.chooseType = type;
            this.chooseOrderRefundFlag = this.form.chooseType === 2;
            this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.REPOSITORY).then((rst) => {
                rst.forEach((e) => {
                    //设置配送中心选项disabled初始状态，根据单据退货设置为true，否则设置为false
                    e.disabled = this.chooseOrderRefundFlag;
                });
                this.depts = rst;
            });
        },
        changeRepo(repoCode) {
            this.noBizFlag = false;
            if (this.chooseOrderRefundFlag) {
                return;
            }
            //根据商品退货处理
            this.form.detailParams = [];
            if (!Util.isEmpty(repoCode)) {
                //查询配送中心是否有不根据单号退货的权限
                get(this.url.noBizFlagUrl + repoCode).then(async (flag) => {
                    this.noBizFlag = flag;
                    if (!this.noBizFlag) {
                        //没有权限
                        this.$message.error('该配送中心没有根据引用商品退货的权限');
                        const selectedRepo = this.depts.find((e) => {
                            return e.code === repoCode;
                        });
                        //将没有权限的配送中心禁用
                        selectedRepo.disabled = true;
                        //重置选中的配送中心
                        this.form.repoCode = '';
                    } else {
                        this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                            this.form.repoCode,
                            'dept.biz.goods.allow.bigUnit'
                        );
                    }
                });
            }
        },
        detailParamPropertyArrForAdd() {
            if (this.chooseOrderRefundFlag) {
                this.$message.error('根据引用单据退货方式无法手动添加商品');
                throw new Error();
            }
            const rst = [
                'goodsCode',
                'stock',
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'giftCounts'),
                new PropertyConverter(null, 'showPrices'),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'bigUnitCounts'),
                    new PropertyConverter(null, 'bulkCounts'),
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        return Util.isEmpty(packFactor) ? 1 : packFactor;
                    })
                );
            }
            return rst;
        },
        showPickUpGoods() {
            const deptName = this.$refs.repoSelect.selected.label;
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        detailParamPropertyArrForOrderShow() {
            if (!this.chooseOrderRefundFlag) {
                this.$message.error('引用商品退货方式无法查询单号');
                throw new Error();
            }
            const rst = [
                'goodsCode',
                'counts',
                new PropertyConverter('giftCount', 'giftCounts'),
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter('counts', 'saleCounts'),
                new PropertyConverter('giftCount', 'saleGiftCounts'),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        //获取单据中商品的包装因子
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter(null, 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Math.floor(detailParam.counts / detailParam.packFactors);
                    }),
                    new PropertyConverter(null, 'bulkCounts', (bulkCounts, detailParam) => {
                        return this.decimal(detailParam.counts)
                            .sub(this.decimal(detailParam.bigUnitCounts).mul(detailParam.packFactors))
                            .toNumber();
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'saleRefundShowMoney', (saleRefundShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(detailParam.counts).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        computeThenChangeCountAndMoney(row) {
            this.$nextTick(() => {
                //计算数量和金额
                let counts;
                if (this.enabledBigUnit) {
                    //启用大单位
                    let bulkCounts = row.bulkCounts || 0;
                    const packFactors = row.packFactors || 1;
                    let bigUnitCounts = row.bigUnitCounts || 0;
                    const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                    if (bigUnitCountsForBulkCounts > 0) {
                        bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                        bulkCounts = this.decimal(bulkCounts)
                            .sub(this.decimal(bigUnitCountsForBulkCounts).mul(packFactors))
                            .toNumber();
                        row.bulkCounts = bulkCounts;
                        row.bigUnitCounts = bigUnitCounts;
                    }
                    counts = this.computeCount(row);
                } else {
                    counts = row.counts || 0;
                }
                row.counts = counts;
                row.saleRefundShowMoney = this.computeShowMoney(row);
                //计算总数量
                row.totalCounts = this.decimal(counts)
                    .add(row.giftCounts || 0)
                    .toNumber();
            });
        },
        computeCount(detailParam) {
            let counts;
            if (this.enabledBigUnit) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            const saleRefundShowMoney = MoneyUtils.moneyToDb(
                this.decimal(detailParam.counts || 0)
                    .mul(detailParam.showPrices || 0)
                    .toNumber()
            );
            return MoneyUtils.moneyToYuan(saleRefundShowMoney);
        },
        async validateAndSelectSaleSelfOutOrder() {
            if (this.orderQuerying) {
                //处理中
                return;
            }
            this.orderQuerying = true;
            //置空，清除数据
            this.form.repoCode = null;
            this.form.bizCode = null;
            this.form.detailParams = [];
            if (!Util.isEmpty(this.selectedDept)) {
                this.selectedDept.disabled = true;
            }
            this.selectedDept = null;

            try {
                if (Util.isEmpty(this.form.saleSelfOrderCode)) {
                    this.$message.error('请输入批发订单号');
                    return false;
                }
                this.loadingStart();
                const saleSelfOrder =
                    (await get(`${this.url.queryOrderPurchaseSaleRelation}${this.form.saleSelfOrderCode}`)) || [];
                let whoseSaleOutCode = null;
                if (saleSelfOrder.length === 0) {
                    this.$message.error('该批发订单还未出库或不存在');
                    return false;
                }
                const wholeSaleOrder = await get(this.url.queryOrder + this.form.saleSelfOrderCode);
                if (this.form.type === 1 && (!wholeSaleOrder || wholeSaleOrder.type === 0)) {
                    this.$message.error('该订单不能建立自建类型的批发退货申请单');
                    return false;
                }
                if (this.form.type === 0 && (!wholeSaleOrder || wholeSaleOrder.type === 1)) {
                    this.$message.error('该订单不能建立采购类型的批发退货申请单');
                    return false;
                }
                whoseSaleOutCode = saleSelfOrder[0].whoseSaleOutCode;
                const saleOutOrder = (await get(this.url.queryOut + whoseSaleOutCode)) || {};
                const matchedDept = this.depts.find((e) => {
                    return !Util.isEmpty(saleOutOrder.deptCode) && e.code === saleOutOrder.deptCode;
                });
                if (Util.isEmpty(matchedDept)) {
                    this.$message.error('该自建批发订单无法匹配上当前退货机构列表');
                    return false;
                }
                //将退货机构列表中匹配上的机构设置为可选且自动选中
                matchedDept.disabled = false;
                this.selectedDept = matchedDept;
                this.form.repoCode = matchedDept.code;
                this.form.bizCode = this.form.saleSelfOrderCode;
                this.formExtend.deptType = matchedDept.type;
                this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                    this.form.repoCode,
                    'dept.biz.goods.allow.bigUnit'
                );
                const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                    saleOutOrder,
                    this.detailParamPropertyArrForOrderShow()
                );
                this.goodsDetailMap = arr.goodsDetails;
                this.form.detailParams = arr.detailParams;
            } finally {
                this.loadingStop();
                this.orderQuerying = false;
            }
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        pickUpGoodsQueryParam() {
            return {
                deptCode: this.form.repoCode,
                page: 1,
                limit: 100,
            };
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                //将商品详情列表转换成多字段数组形式
                const createParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete createParam.detailParams;
                await postByFormDataAfterConfirmed(`/trade/wholesale/refund/createExtend`, createParam);
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
